<template>
  <div
    class="product-grid"
    :class="{ 'search-no-product-bg': totItems == 0 && !loading }"
  >
    <v-container>
      <v-row>
        <v-col
          cols="12"
          sm="4"
          lg="3"
          xl="3"
          v-if="!$vuetify.breakpoint.xs && view != 'list'"
        >
          <div>
            <h4 class="primary--text text-capitalize font-weight-bold">
              {{ $t("product.filter") }}: {{ totItems }}
            </h4>
            <ProductFacetFilterDesktop
              v-if="$vuetify.breakpoint.smAndUp"
              :facets="facets"
              :facetsFilter="facetsFilter"
              :filteredProductsCount="filteredProductsCount"
              :hideFilterString="hideFilterString"
              @handleFacetFilterMobile="handleFacetFilterMobile"
            />
          </div>
        </v-col>
        <v-col :cols="cols" :sm="sm" :lg="lg" :xl="xl">
          <ProductSortFilter
            v-if="!$vuetify.breakpoint.xs"
            :products="alteredProducts"
            :sortFilter="sortFilter"
            :view="view"
            :facets="facets"
            :actions="actions"
            :facetsFilter="facetsFilter"
            :filteredProductsCount="filteredProductsCount"
            :hideFilterString="hideFilterString"
            :cart="false"
            @reload="fetchProducts"
            @handleFacetFilterMobile="handleFacetFilterMobile"
            @handleSortFilter="handleSortFilter"
            @handleView="handleView"
            @handleKey="handleKey"/>
          <div
            class="d-flex justify-start align-center primary lighten-2 pa-3 mx-n3 mb-3"
            style="flex:1"
            v-else
          >
            <v-btn
              elevation="0"
              text
              style="flex-grow:0"
              color="primary"
              @click.stop="drawer = !drawer"
            >
              <v-icon>mdi-tune</v-icon>
            </v-btn>
            <div class="d-flex flex-nowrap" style="flex-grow:1; overflow: auto">
              <v-slide-group multiple show-arrows>
                <v-slide-item
                  v-for="(selectedFilter, index) of selectedFilters"
                  :key="index"
                >
                  <v-chip
                    class="ma-2"
                    color="primary"
                    label
                    @click="chipClicked(selectedFilter)"
                    v-if="selectedFilter.paramType == 'Range'"
                  >
                    {{ selectedFilter.name }} :
                    {{ selectedFilter.items[1].min }}-{{
                      selectedFilter.items[1].max
                    }}
                  </v-chip>
                  <v-chip
                    class="ma-2"
                    color="primary"
                    label
                    @click="chipClicked(selectedFilter)"
                    v-else
                  >
                    {{ selectedFilter.name }}
                  </v-chip>
                </v-slide-item>
              </v-slide-group>
            </div>
          </div>
          <div style="max-height:100%" v-if="totItems > 0">
            <ProductGridMobile
              v-if="$vuetify.breakpoint.xs"
              :products="alteredProducts"
              :totalItems="totItems"
              :position="position"
              @reload="fetchProducts"
            ></ProductGridMobile>
            <component
              v-else
              :is="templateName"
              :products="alteredProducts"
              :totalItems="totItems"
              :position="position"
              @reload="fetchProducts"
              :key="componentKey"
            ></component>
          </div>
          <div v-else-if="!loading" class="pa-12 text-center">
            <div class="no-products-title">
              Non sono stati trovati prodotti.
            </div>
            <div class="pa-8">
              <img src="/no-products-cart.svg" alt="Nessun prodotto trovato" />
            </div>
            <!-- <div class="no-products-legend align-center">
              <i18n path="products.noProductsFound" :tag="false">
                <template v-slot:br><br /></template>
                <template v-slot:link
                  ><router-link
                    :to="{
                      name: 'Page',
                      params: { pageName: 'servizio-clienti' }
                    }"
                  >
                    {{ $t("products.linkServizioClienti") }}
                  </router-link>
                </template>
              </i18n>
            </div> -->
          </div>
          <v-btn
            block
            v-intersect="onIntersect"
            :loading="loading"
            class="primary"
            @click="loadMore"
            v-if="$vuetify.breakpoint.xs && hasMoreItems"
          >
            <template v-if="hasMoreItems">{{
              $t("products.showMore")
            }}</template>
            <template v-else>{{ $t("products.showAll") }}</template>
          </v-btn>
          <div class="d-flex justify-start">
            <v-pagination
              v-if="pager && pager.totPages > 1 && !$vuetify.breakpoint.xs"
              :value="pageFilter"
              :page="pager.selPage"
              :length="pager.totPages ? pager.totPages : 0"
              :totalVisible="7"
              @next.stop.prevent="handlePageFilter"
              @prev.stop.prevent="handlePageFilter"
              @input="handlePageFilter"
            ></v-pagination></div
        ></v-col>
      </v-row>
      <v-navigation-drawer
        v-model="drawer"
        app
        temporary
        floating
        right
        width="100%"
        min-width="50%"
        ><ProductFilterDraver
          :facets="facets"
          :facetsFilter="facetsFilter"
          :filteredProductsCount="filteredProductsCount"
          :hideFilterString="hideFilterString"
          @close="drawer = false"
          @handleFacetFilterMobile="handleFacetFilterMobile"
      /></v-navigation-drawer>
    </v-container>
    <!-- {{ selectedProducts }} -->
  </div>
</template>

<script>
import CategoryMenu from "@/components/navigation/CategoryMenu.vue";
import ProductSortFilter from "@/components/product/filters/ProductSortFilter.vue";
import ProductFilterDraver from "./filters/ProductFilterDraver.vue";
import ProductPromoFilter from "@/components/product/filters/ProductPromoFilter.vue";
import ProductFacetFilterMobile from "./filters/ProductFacetFilterMobile.vue";
import ProductFacetFilterDesktop from "./filters/ProductFacetFilterDesktop.vue";
import ProductGridSmall from "./ProductGridSmall.vue";
import ProductGridBig from "./ProductGridBig.vue";
import ProductGridMobile from "./ProductGridMobile.vue";
import ProductTableView from "./ProductTableViewTest.vue";

import ProductService from "~/service/productService";
import analyticsService from "~/service/analyticsService";

import { mapGetters, mapState } from "vuex";

export default {
  name: "ProductListGrid",
  props: {
    parentCategoryId: { type: Number, required: false },
    categoryId: { type: Number, required: false },
    query: { type: String, required: false },
    productCodes: { type: String, required: false },
    versionDateFrom: { type: String, required: false },
    versionDateTo: { type: String, required: false },
    dateFrom: { type: Date, required: false },
    webEnabled: { type: Boolean, required: false, default: true },
    dateTo: { type: Date, required: false },
    barcode: { type: String, required: false },
    mode: { type: String, required: false },
    hidePromoFilter: { type: Boolean, default: false },
    hideFilterString: { type: String, required: false },
    hideSort: { type: Boolean, default: false },
    category: { type: Object, required: false },
    position: { type: String, required: false },
    sort: { type: String, required: false }
  },
  components: {
    CategoryMenu,
    ProductSortFilter,
    ProductPromoFilter,
    ProductFacetFilterMobile,
    ProductFacetFilterDesktop,
    ProductGridSmall,
    ProductGridBig,
    ProductGridMobile,
    ProductTableView,
    ProductFilterDraver
  },
  data() {
    return {
      products: [],
      facets: [],
      pager: {},
      componentKey: 0,
      actions: [
        {
          title: this.$t("product.actions.getImages"),
          to: "GetImages"
        },
        {
          title: this.$t("product.actions.extractProductData")
        }
      ],
      page: 1,
      busy: false,
      loading: false,
      filteredProductsCount: 0,
      view: "small-grid",
      drawer: false,
      cols: 12,
      sm: 8,
      lg: 9,
      xl: 9
    };
  },
  computed: {
    alteredProducts() {
      this.products?.forEach(product => {
        if (
          this.selectedProducts &&
          this.selectedProducts.some(
            e => e.product.productId === product.productId
          )
        ) {
          product.highlight = true;
        } else product.highlight = false;
        if (
          this.productsInCart &&
          this.productsInCart.some(
            e => e.product.productId === product.productId
          )
        ) {
          product.inCart = true;
        } else product.inCart = false;
      });
      return this.products;
    },
    templateName() {
      if (this.view === "list") {
        return "ProductTableView";
      } else if (this.view === "big-grid") {
        return "ProductGridBig";
      } else return "ProductGridSmall";
    },
    selectedFilters() {
      if (this.facets) {
        let y = this.facets;
        let x = y
          .map(facet => {
            if (facet.paramType == "Range") {
              return facet;
            } else {
              return facet.values
                .filter(
                  value =>
                    this.facetsFilter[facet.parameterName] &&
                    this.facetsFilter[facet.parameterName].includes(value.id)
                )
                .map(value => {
                  return { ...value, parameterName: facet.parameterName };
                });
            }
          })
          .reduce((a, b) => a.concat(b), []);
        return x.filter(value => {
          return value.paramType != "Range" || this.displayRangeFilter(value);
        });
      }
      return [];
    },
    queryLabel() {
      return this.barcode ? this.barcode : this.query;
    },
    currentRouteName() {
      return this.$route.name;
    },
    totItems() {
      return this.pager && this.pager.totItems ? this.pager.totItems : 0;
    },
    ...mapState({
      bookmarkedProducts: ({ cart }) => cart.cart
    }),
    ...mapGetters({
      promoFilter: "category/promoFilter",
      newFilter: "category/newFilter",
      sortFilter: "category/sortFilter",
      pageFilter: "category/pageFilter",
      //bookmarkedProducts: "bookmarks/getCartItems"
      selectedProducts: "selectedProducts/getProducts",
      productsInCart: "cart/getProducts"
    }),

    facetsFilter: {
      get() {
        return this.$store.state.category.filters.facets;
      },
      set() {
        // this.$store.commit("updatedDrawerLeft", value);
      }
    },

    hasMoreItems() {
      if (this.pager) {
        return this.pager.totPages > this.pager.selPage;
      } else {
        return false;
      }
    }
  },
  methods: {
    handleView(view) {
      this.view = view;
      if (view === "list") {
        this.sm = 12;
        this.lg = 12;
        this.xl = 12;
      } else {
        this.sm = 8;
        this.lg = 9;
        this.xl = 9;
      }
    },
    handleKey() {
      this.componentKey += 1;
    },
    handleSortFilter(sort) {
      this.$store.dispatch("category/setFilterSort", sort);
      this.fetchProducts();
    },
    handleNewFilter(onlyNew) {
      this.$store.dispatch("category/setFilterNew", onlyNew);
      this.fetchProducts();
    },
    handlePromoFilter(promo) {
      this.$store.dispatch("category/setFilterPromo", promo);
      this.fetchProducts();
    },
    handleFacetFilter(filter) {
      this.$store.dispatch("category/setFilterFacets", {
        facets: filter.facets,
        parameterName: filter.parameterName
      });
      this.fetchProducts();
    },
    handleFacetFilterMobile(selectedFacetsXS) {
      this.$store.dispatch("category/setFilterFacetsMobile", {
        facets: selectedFacetsXS
      });
      this.fetchProducts();
    },
    handlePageFilter(page) {
      var _this = this;
      this.$store.dispatch("category/setFilterPage", page);
      //await di this.fetchProduts
      this.fetchProducts();
      //oppure mettere goTo(0) dentro timeout
      setTimeout(function() {
        _this.$vuetify.goTo(0);
      }, 300);
    },

    onIntersect(entries) {
      if (entries[0].isIntersecting) {
        this.loadMore();
      }
    },
    loadMore() {
      if (this.hasMoreItems) {
        this.$store.dispatch("category/setFilterPage", this.pageFilter + 1);
        this.fetchProducts(true);
      }
    },
    async fetchProducts(append) {
      this.loading = true;
      let sortLocal = "rank";
      if (this.sort) {
        sortLocal = this.sort;
      } else if (this.sortFilter) {
        sortLocal = this.sortFilter;
      }
      let response = await ProductService.search(
        {
          parent_category_id: this.parentCategoryId,
          category_id: this.categoryId,
          q: this.query,
          product_codes: this.productCodes,
          version_date_from: this.versionDateFrom,
          version_date_to: this.versionDateTo,
          web_enabled: this.webEnabled,
          pubdate_from: this.dateFrom,
          pubdate_to: this.dateTo,
          barcode: this.barcode,
          page: this.pageFilter,
          page_size: process.env.VUE_APP_PRODUCT_LIST_PAGE_SIZE,
          sort: sortLocal,
          promo: this.promoFilter,
          new_product: this.newFilter
        },
        this.facetsFilter
      );

      // Se ho letto il barcode e mi torna un Prodotto, navigo diretto al Dettaglio
      if (this.barcode && response.products.length > 0) {
        this.$router.push({
          name: "Product",
          params: { slug: response.products[0].slug }
        });
      } else if (append) {
        this.products = [...this.products, ...response.products];
      } else {
        this.products = response.products;
      }
      if (response.products?.length > 0) {
        analyticsService.viewProducts(
          response.products,
          this.position,
          (response.page.selPage - 1) * response.page.itemsPerPage
        );
      }
      this.pager = response.page;
      this.facets = response.facets;
      this.$emit("productsCount", this.pager.totItems);
      this.filteredProductsCount = response.page.totItems;
      if (this.pager.searchedText) {
        this.$emit("searchedText", this.pager.searchedText);
      }
      this.loading = false;
    },
    selectFilter(e) {
      console.log(e);
    },
    chipClicked(item) {
      let filters = global.vm.lodash.cloneDeep(this.facetsFilter);
      if (item.paramType == "Range") {
        filters[item.parameterName + "_from"] = item.items[0].min;
        filters[item.parameterName + "_to"] = (
          parseFloat(item.items[0].max) + 0.0005
        ).toString();
      } else {
        filters[item.parameterName].splice(
          this.facetsFilter[item.parameterName].indexOf(item.id),
          1
        );
      }

      this.handleFacetFilterMobile(filters);
    },
    displayRangeFilter(filter) {
      if (
        filter.items[0].min == filter.items[1].min &&
        filter.items[0].max == filter.items[1].max
      ) {
        return false;
      }
      return true;
    }
  },
  async created() {
    if (this.$vuetify.breakpoint.xsOnly) {
      let page = this.pageFilter;
      for (let i = 1; i <= page; i++) {
        this.$store.dispatch("category/setFilterPage", i);
        await this.fetchProducts(true);
      }
    } else {
      this.fetchProducts();
    }
  }
};
</script>
